'use strict';

angular.module('informaApp')
    .controller('AdminCtrl', ['$routeParams', '$timeout', '$scope', '$location',
        function ($routeParams, $timeout, $scope, $location) {
            $scope.setView = setView;

            $scope.sections = {
                users: 'users',
                usage: 'usage',
                faq: 'faq',
                tooltips: 'tooltips',
                systemNotifications: 'system-notifications',
                askAnalyst: 'ask-analyst',
                whatsNew: 'whats-new'
            };

            function setView(view, cb, doNotChangeLocation) {
                $scope.view = view;

                if (!doNotChangeLocation) {
                    $location.path('/admin/' + view);
                }

                if (cb) {
                    cb()
                }
            }

            function processRoutes() {
                processSection($routeParams.section);
            }

            function processSection(section) {
                const sectionList = _.values($scope.sections);

                if (sectionList.indexOf(section) > -1){
                    $scope.setView(section, null, true);
                } else {
                    $scope.setView($scope.sections.users);
                }
            }

            processRoutes();
        }
    ]);
